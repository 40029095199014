// DISCLAIMER
// NON HO MESSO TUTTI GLI STILI DELLA LANDING PAGE PROVENIENTI DAGLI ALTRI FILE
// MA SOLO QUELLI CHE HO INIZIATO A SCRIVERE IO
// <3 PREDO

#lottie-giorno-notte {
    position: relative;
    height: 180px;
    width: 100%;
    margin: auto;

    @media (max-width: 576px) {
        height: 177px;
    }

    @media (min-width: 992px) {
        height: 70%;
    }
    
    .lottie {
        height: 140%;
        display: block;
        z-index: 10;
        bottom: 5%;
        @media (min-width: 768px) and (max-width: 1200px) {
            bottom: 18%;
        }
        @media (min-width: 1200px) {
            display: inline;
            position: relative;
            bottom: 10%;
        }
    }
}

.title-box-landing {
    height: 100% !important;
    width: 100% !important;
  }