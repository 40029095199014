@font-face {
  font-family: "Roobert";
  src: url("./fonts/WEB/RoobertENEL-Regular.woff") format("woff"),
    url("./fonts/WEB/RoobertENEL-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roobert Light";
  src: url("./fonts/WEB/RoobertENEL-Light.woff") format("woff"),
    url("./fonts/WEB/RoobertENEL-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roobert Bold";
  src: url("./fonts/WEB/RoobertENEL-Bold.woff") format("woff"),
    url("./fonts/WEB/RoobertENEL-Bold.woff2") format("woff2");
}

// @font-face {
//   font-family: "Roobert";
//   src: url("./fonts/WEB/RoobertENEL-Black.woff") format("woff"),
//     url("./fonts/WEB/RoobertENEL-Black.woff2") format("woff2");
// }

body {
  font-family: "Roobert";
}

.small-text {
  font-size: 0.875rem;
  color: #667790;
  line-height: 1rem;
}

$purple100: #461e7d;
$purple80: rgba(70, 30, 125, 0.8);
$purple60: rgba(70, 30, 125, 0.6);
$purple40: rgba(70, 30, 125, 0.4);
$purple20: rgba(70, 30, 125, 0.2);

$newPrimary: #0047CC;

$gray100: #333333;
$gray80: #4f4f4f;
$gray60: #828282;
$gray40: #dddddd;
$gray20: #cecece;
$gray0: #f4f4f4;

$success100: #00855d;
$success80: rgba(0, 133, 93, 0.8);
$success60: rgba(0, 133, 93, 0.6);
$success40: rgba(0, 133, 93, 0.4);
$success20: rgba(0, 133, 93, 0.2);

$accent100: #d3135a;
$accent80: rgba(211, 19, 90, 0.8);
$accent60: rgba(211, 19, 90, 0.6);
$accent40: rgba(211, 19, 90, 0.4);
$accent20: rgba(211, 19, 90, 0.2);

$warning100: rgba(230, 115, 0, 1);
$warning80: rgba(230, 115, 0, 0.8);
$warning60: rgba(230, 115, 0, 0.6);
$warning40: rgba(230, 115, 0, 0.4);
$warning20: rgba(230, 115, 0, 0.2);

$error100: #ed0529;
$error80: rgba(237, 5, 41, 0.8);
$error60: rgba(237, 5, 41, 0.6);
$error40: rgba(237, 5, 41, 0.4);
$error20: rgba(237, 5, 41, 0.2);

$cyan100: #28d7dc;
$cyan80: rgba(40, 215, 220, 0.8);
$cyan60: rgba(40, 215, 220, 0.6);
$cyan40: rgba(40, 215, 220, 0.4);
$cyan20: rgba(40, 215, 220, 0.2);

// @supports (-webkit-touch-callout: none) {
//   /* CSS specific to iOS devices */
//   .select-popup.riepilogo.contacts.modal.show {
//     padding-top:60px;
//   }
// }

@media (max-width: 350px) {
  .confirm {
    max-height: 40px;
    button {
      max-height: 30px;
    }
  }

  .close-button {
    top: 2px !important;
  }

  .select-popup .title .field-name {
    margin: 0;
    padding: 8px;
    max-width: 70%;
  }

  .mt-2 {
    margin-top: 0 !important;
  }

  .so-non-so {
    max-height: 40px;
    button {
      max-height: 30px;
    }
  }
}

@media (max-width: 768px) {

  .thanks  {
    .container {
    h2, p {
      color: white;
    }
  }
  }
  .product {
    flex-wrap: wrap;
  }
  .final .header .right-side .prodotti .product .bottom {
    margin-left: 0;
  }
  .product-modal {
    .box-component img {
      max-width: unset;
      max-height: 200px;
      object-fit: contain;
    }
  }
  .box-component .tags {
    align-items: flex-start;
  }
  .tags {
    flex-direction: column;
    align-items: flex-start;
    .tag {
      margin-bottom: 0.25rem;
    }
  }
  .white-bg {
    main {
      background-color: white;
    }
  }

  .main {
    overflow-x: hidden;
  }
  .select-option,
  .box {
    .main {
      overflow: hidden;
    }
  }
  .contacts {
    max-height: 100vh;
    // max-height: 100%;
    // overflow-y: auto !important;
    // justify-content: flex-start;

    // .container-fluid {
    //   max-height: 100%;
    //   overflow-y: auto;
    // }
  }
  .desktop-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    border-top: 1px solid #c2cddd;
  }
  .mw730,
  .mv344,
  .mw320 {
    max-width: unset !important;
  }

  #form-header {
    &.extra-margin {
      #enel-logo img {
        position: absolute;
        top: 2rem;
      }
      // .title-box {
      //   margin-top: -18px;
      //   height:260px;
      // }
      .progress-box {
        margin-top: 4rem;
      }
    }
    // padding-bottom: 3rem;
    .title {
      h2 {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }
    .subtitle {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 2rem;
    }
  }
  #back-button {
    width: 100px;
  }
  #next-button {
    flex-grow: 1;
    width: 100px;
  }
  .choice-box {
    border: 1px solid #c2cddd;
    button.no-bold {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    button.selected {
      border: 1px solid $accent100 !important;

      &:first-child {
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
      }

      &:last-child {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
      }
    }
  }
  .my-32 {
    margin-bottom: 32px;
    margin-top: 32px;
  }
  .mb-32 {
    margin-bottom: 32px;
  }
  .mb-sm-16 {
    .title {
      margin-bottom: 16px !important;
    }
  }
  .mt-sm-0 {
    .title {
      margin-top: 0px !important;
    }
  }

  .actual-futura {
    flex-grow: 1;
  }

  #landing {
    //MARCO: moved to dedicated file
    // #banner-image {
    //   width: 90%;
    //   // margin-top: -50px;
    // }
    .title-box {
      background-position: top right;
      .title-text {
        //font-size: 1.5rem;
        margin-top: 0rem;
      }
      // background-image: unset !important;
    }
  }

  .title-box {
    background-position: top;
    background-color: $newPrimary;
    background-repeat: no-repeat;
    background-position: right;
    //margin-top: -10px;
    padding: 1.5rem 1rem;
    background-size: contain;
    background-image: url("../icons/banner/bkg.svg");
    
    @media (max-width: 1003px) {
      height: 280px;
    }

    .subtitle {
      display: none;
    }

    .header-image-container {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      position: relative;
      margin-top: -44px;

      //MARCO: moved to dedicated file
      // #banner-image {
      //   width: 100%;
      //   // top:-30px;
      //   // keep this stile in order to maintain the aspect ratio and proportion with child images
      //   // position: relative;
      //   // width: 10rem;
      //   // height: 14.5rem;
      //   // width: 160px;
      //   // height: 232px;
      //   object-fit: contain;
      //   display: inline-block;
      //   margin: 0;
      //   img {
      //     position: absolute;
      //     display: block;
      //   }
      // }
    }

    .title-text {
      color: #fff;
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0.4px;
      text-align: center;
      width: 100%;
      &.title-text-step {
        text-align: left;
        flex: 0.5
      }
    }
    .title {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 700;
    }
  }

  .modal-dialog.modal-dialog-centered.modal-fullscreen .confirm button {
    width: 100%;
    max-width: 100%;
    margin: 0px;
    // margin-left:16px;
    // margin-right: 16px;
  }

  #landing {
    #tondo {
      display: none !important;
    }
    // header#form-header {
    //   height: 290px;
    // }
    .landing-box {
      .landing-text {
        // margin-top: 2rem;
        padding-top: 2rem;
      }
      .landing-row-component {
        .landing-row-image {
          width: 3rem;
          height: 3rem;
          margin-right: 1rem;
        }

        .landing-row-title {
          font-size: 1.125rem;
        }
      }
    }

    .small-text {
      line-height: 1.5rem;
    }

    .next-button-box {
      button {
        max-width: 100%;
        width: 100%;
      }
    }

    .info-box {
      .content {
        .title {
          margin-bottom: 16px;
          margin-top: 0px;
        }
      }
    }
  }

  .info-box {
    .content {
      .title {
        margin-bottom: 16px;
        margin-top: 0px;
      }
    }
  }

  .single-box {
    border: 1px solid #c2cddd;

    &.first {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
  }

  #risparmio-preliminare {
    .actual-futura {
      .f14 {
      }
    }

    .risparmio-box-total {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .warning {
      border: 2px solid #e67300;
      border-radius: 8px;
      justify-content: center;
      align-items: center;
      padding: 16px;

      .text {
        font-size: 1rem;
        line-height: 1.5rem;
        b {
          line-height: 1.5rem;
          font-size: 1rem;
        }
      }

      .icon {
        margin-right: 16px !important;
      }

      .close {
        margin-left: 32px;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .header-mobile .sub-header .prodotti {
    background-color: #f7f8fb;
    padding-bottom: 8px;
  }
  .label-component {
    font-size: 18px;
  }

  .contacts {
    &.select-popup {
      background-color: white;
    }

    .switch-component {
      padding: 16px;
      input[type="checkbox"] {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
    .title,
    .end-button {
      // box-shadow: none;
      z-index: 10000;
      background-color: white;
    }

    .end-button {
      width: 100%;
    }

    .switch-text {
      line-height: 1rem;
      color: #313b49;
      font-size: 1rem;
    }
  }

  .select-popup {
    background: linear-gradient(
      360deg,
      #ffffff 0%,
      #ffffff 82.97%,
      rgba(255, 255, 255, 0) 103.45%
    );
    .confirm {
      background: white;
      .w-100 {
        padding-bottom: 1rem;
      }
    }
  }

  .single-next {
    width: 100%;
  }
  #risparmio.preliminare {
    // .mt-5{
    //   margin-top: 16px !important;
    // }
  }
  .final {
    .review-or-download {
      text-align: center;
      margin-bottom: 56px;

      .mr-16 {
        margin-right: 0 !important;
      }

      button.outline.btn.btn-primary {
        margin-bottom: 16px;
        max-width: unset;
        width: 100%;
      }
    }

    .benefici {
      padding-top: 24px;
      padding-bottom: 24px;
      text-align: left;

      .title {
        font-size: 1.5rem;
        text-align: left;
        line-height: 2rem;
      }

      .subtitle {
        text-align: left;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #313b49;
        margin-bottom: 16px;
      }

      .disclaimer {
        margin-top: 0 !important;
      }

      .box {
        margin-bottom: 24px;
        .box-info {
          font-size: 1.125rem;
          line-height: 1.75rem;
          color: #131416;
          text-align: left;
        }

        .box-content {
          .box-top {
            align-items: center;

            .sub {
              // font-weight: 700;
              font-size: 1rem;
              line-height: 1rem;
            }
          }
          .box-bottom {
            font-size: 12px;
            line-height: 1.125rem;
            .mb-8 {
              font-size: 14px;
              line-height: 1.25rem;
            }

            .money {
              font-size: 18px;
              line-height: 1.625rem;
            }
          }
        }
      }
    }

    .book-appointment {
      background: unset;
      color: #000103;
      padding-top: 0px;

      h2 {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2rem;
        text-align: center;
        color: #131416;
      }

      p {
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.75rem;
        text-align: center;
        color: #000103;
      }

      button {
        background-color: $accent100;
        color: white;
        border: 0;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1rem;
        // text-transform: uppercase;
        color: #ffffff;
        width: 100%;
        border: 0;
      }
    }
  }

  .slider-component {
    .pre {
      font-size: 18px;
      line-height: 1.5rem;
    }

    .description {
      font-size: 14px;
      line-height: 1.25rem;
    }
  }

  .form-page-component {
    padding-top: 24px;
    padding-left: 1rem;
    padding-right: 1rem;
    // padding-bottom: 24px;
  }

  #landing {
    .landing-box {
      // padding-bottom:50px;
      background-color: white;
    }
    .subtitle {
      display: none;
    }

    .title {
      font-size: 24px;
      margin-top: 8px;
      line-height: 2rem;
    }

    padding-bottom: 0px;

    .next-button-box {
      position: fixed;
      left: 16px;
      right: 16px;
      bottom: 0;
      // background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 82.97%, rgba(255, 255, 255, 0) 103.45%);
    }
  }

  .next-button-box {
    position: fixed;
    bottom: 0;
    // margin-bottom:3rem;
    // border-top: 0;
    button {
      // max-width: 100px;
    }
    // background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 82.97%, rgba(255, 255, 255, 0) 103.45%);
  }
}


//MARCO: moved to dedicated file
// @media (max-width: 375px) {
//   #landing {
//     #banner-image {
//       width: 100%;
//       margin-top: 0px;
//     }
//   }
// }


//   .header-image-container {
//     display:none;
//   }
//   #landing header#form-header {
//      height: 200px;
// }

//     #form-header {
//       &.extra-margin {
//         .title-box {
//           margin-top: -18px;
//           height:200px;
//         }
//       }
//     }
// }

// @media (max-width: 700px) {
//   #image-wrapper {
//   margin-top: -100px;
// }
// }
