@font-face {
  font-family: "Roobert";
  src: url("./fonts/WEB/RoobertENEL-Regular.woff") format("woff"),
    url("./fonts/WEB/RoobertENEL-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roobert Light";
  src: url("./fonts/WEB/RoobertENEL-Light.woff") format("woff"),
    url("./fonts/WEB/RoobertENEL-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Roobert Bold";
  src: url("./fonts/WEB/RoobertENEL-Bold.woff") format("woff"),
    url("./fonts/WEB/RoobertENEL-Bold.woff2") format("woff2");
}

// @font-face {
//   font-family: "Roobert";
//   src: url("./fonts/WEB/RoobertENEL-Black.woff") format("woff"),
//     url("./fonts/WEB/RoobertENEL-Black.woff2") format("woff2");
// }

body {
  font-family: "Roobert";
}

.small-text {
  font-size: 0.875rem;
  color: #667790;
  line-height: 1rem;
}

$purple100: #461e7d;
$purple80: rgba(70, 30, 125, 0.8);
$purple60: rgba(70, 30, 125, 0.6);
$purple40: rgba(70, 30, 125, 0.4);
$purple20: rgba(70, 30, 125, 0.2);

$newPrimary: #0047CC;

$gray100: #333333;
$gray80: #4f4f4f;
$gray60: #828282;
$gray40: #dddddd;
$gray20: #cecece;
$gray0: #f4f4f4;

$success100: #00855d;
$success80: rgba(0, 133, 93, 0.8);
$success60: rgba(0, 133, 93, 0.6);
$success40: rgba(0, 133, 93, 0.4);
$success20: rgba(0, 133, 93, 0.2);

$accent100: #d3135a;
$accent80: rgba(211, 19, 90, 0.8);
$accent60: rgba(211, 19, 90, 0.6);
$accent40: rgba(211, 19, 90, 0.4);
$accent20: rgba(211, 19, 90, 0.2);

$warning100: rgba(230, 115, 0, 1);
$warning80: rgba(230, 115, 0, 0.8);
$warning60: rgba(230, 115, 0, 0.6);
$warning40: rgba(230, 115, 0, 0.4);
$warning20: rgba(230, 115, 0, 0.2);

$error100: #ed0529;
$error80: rgba(237, 5, 41, 0.8);
$error60: rgba(237, 5, 41, 0.6);
$error40: rgba(237, 5, 41, 0.4);
$error20: rgba(237, 5, 41, 0.2);

$cyan100: #28d7dc;
$cyan80: rgba(40, 215, 220, 0.8);
$cyan60: rgba(40, 215, 220, 0.6);
$cyan40: rgba(40, 215, 220, 0.4);
$cyan20: rgba(40, 215, 220, 0.2);

@media (min-width: 992px) {
  .tags {
    flex-direction: column;
    align-items: start !important;
    .tag {
      margin-bottom: 0.25rem;
    }
  }
  //MARCO: moved to dedicated file
  // #banner-image {
  //   bottom: 0px;
  // }
  .bold-title {
    margin-bottom: 1rem;
  }
  .f14 {
    font-size: 0.777rem;
  }
  #next-button {
    width: 344px;
  }
  .prodotti .product .d-flex.justify-content-between.mt-16 {
    max-height: 40px;
  }

  .product {
    display: flex;
    flex-direction: row;
    border: 0;
  }
  .switch-description {
    max-width: 440px;
  }

  .final {
    // margin-bottom: 130px;
    .final-body {
      max-width: 884px;
      margin: 0 auto;
      padding-left: 3rem;
      padding-right: 3rem;
    }

    .header {
      background: white;
      margin-bottom: 4rem;
      margin-top: 1rem;
      padding-top: 0;
      padding-bottom: 0;
      // padding-top:40px;
      // padding-bottom:40px;
    }

    .subtitle {
      font-family: "Roobert";
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2rem;
      color: #131416;
      padding-bottom: 24px;
    }

    #left-banner {
      background: $newPrimary;
      position: relative;
      min-height: 100vh;
      overflow-y: hidden;

      #tondo {
        right: 0px;
        left: unset;
      }
      // background-image: url("/icons/houses.png");
      margin: 0;
      padding-top: 80px;
      padding-left: 64px;
      // position: relative;

      div {
        // position: fixed;
        left: 0;
        right: 0;
      }

      .logo > img {
        padding-bottom: 3rem;
        width: 123px;
        position: sticky;
      }

      h1 {
        margin-top: 1rem;
        font-family: "Roobert";
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 125%;
        letter-spacing: -1.28px;
        color: white;
        width: 100%;
        display: block;
        position: sticky;
        z-index: 10;
      }
    }

    #right-banner {
      // margin:0;
      // padding:0px 60px;
      position: relative;

      // max-width: 884px;
      margin: auto;
      height: 100vh;
      overflow-y: auto;

      .btn-group {
        box-shadow: 0px var(--shadow-size, 2px) var(--shadow-blur, 8px) 0px
          var(--shadow-color, rgba(102, 119, 144, 0.2));
        padding: 4px;
      }
      .desktop-cta {
        // width: 100%;
        left: unset;
        right: unset;
        // width: 60%;
        // max-width: 60%;
        justify-content: end;
        padding-right: 4rem;
        display: flex;
        position: sticky;
        background-color: white;
        border-top: 1px solid #c2cddd;
      }

      .right-side {
        padding: 0px;
        // max-width: 740px;
        margin: 0 auto;
      }

      .review-or-download-component {
        margin-bottom: 0px;
      }

      #title {
        color: #131416;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 125%;
        letter-spacing: 0.4px;
        margin-top: 2rem;
      }

      .benefici {
        padding-top: 0px;
        padding-bottom: 0px;
        text-align: left;

        .same-height .box {
          padding-left: 0px;
          padding-right: 0px;
          margin-right: 0px;
          margin-left: 0px;
        }

        .subtitle {
          font-family: "Roobert";
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 700;
          line-height: 2rem;
          color: #131416; // #461E7D;
          padding-bottom: 24px;
          text-align: left;
          padding: 0px;
          margin: 0px;
        }

        .paragraph {
          margin-top: 8px;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.25rem;
          padding: 0px;
        }

        // .box-info {
        //   color: #131416;// #461E7D;
        //   /* headings/heading_md */
        //   font-family: "Roobert";
        //   font-size: 1.125rem;
        //   font-style: normal;
        //   font-weight: 700;
        //   line-height: 1.5rem;
        //   text-align: left;
        //   padding-left: 0px;
        //   padding-right: 0px;
        //   padding-top:24px;
        //   padding-bottom:16px;
        //   margin:0;

        // }

        .box-content {
          flex-direction: row;
          align-items: center;

          .box-top {
            margin-bottom: 0;
            flex-grow: 1;
            flex-shrink: 1;
            width: 50%;

            .sub {
              font-size: 1rem;
            }
          }

          .box-middle {
            flex-grow: 1;
            flex-shrink: 1;
            width: 50%;
          }
        }
      }
    }
  }
  .mbl24 {
    margin-bottom: 24px;
  }
  .mbl48 {
    margin-bottom: 48px;
  }
  .spacer {
    display: none;
  }
  .final-header {
    img {
      height: 32px;
    }
    .btn {
      margin-left: 0px !important;
      padding: 12px 24px;
      line-height: 12px;
    }
  }

  .winfo {
    max-width: 440px;
  }
  #root {
    overflow: hidden;
  }
  .main.row {
    padding-right: 0px;
  }
  #landing {
    .title {
      z-index: 2;
      position: relative;
    }
    // background: linear-gradient(0deg, #170a28 -1.25%, #451e7c 100%);
    #form-header {
      //padding-top: 0rem;
      z-index: unset;
      //padding-left: 4rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: unset !important;
    }
    .landing-box {
      margin-top: 2rem;
      margin-bottom: 2rem;
      // background: white;
    }
  }

  .main-select-control {
    display: flex;
    justify-content: start;
    align-items: start;
    width: 430px;
    height: 46px;
    position: relative;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      margin-left: 16px;
    }

    .main-select-text {
      display: flex;
    }

    .prop-name {
      color: #667790;
      font-size: 0.75rem;
      position: absolute;
      top: 0;
      left: 10px;
    }

    [class$="control"] {
      width: 430px !important;
      [class*="singleValue"] {
        font-size: 0.875rem;
        margin: 0px;
      }
    }
  }

  .empty-select-control {
    height: 46px;
    width: 100%;
    min-width: 100%;
    [class^="placeholder"] {
      display: none !important;
    }
    [class$="control"] {
      width: 430px !important;
    }
  }

  .dropdown-new {
    border: 0;
    padding-right: 16px;
    padding-left: 16px;
  }

  .selected-opt {
    // background-color: red !important;
    color: $accent100;
    display: flex;
    justify-content: flex-start;
    align-items: space-between;
    padding: 12px;
    width: 100%;
    z-index: 2;
    position: relative;
    cursor: pointer;

    .check-freccia {
      position: absolute;
      top: 8px;
      right: 8px;
    }

    &.focused {
      background-color: $accent20;
    }
  }

  .non-selected-opt {
    display: flex;
    justify-content: flex-start;
    align-items: space-between;
    padding: 16px;
    width: 100% !important;
    z-index: 2;
    position: relative;
    cursor: pointer;
    &:focus {
      background-color: red !important;
    }

    &:focus-within {
      background-color: $accent20;
    }

    &:focus-visible {
      background-color: $accent20;
    }

    img {
      filter: brightness(0) contrast(100%) grayscale(100%);
    }
  }

  .percentage-component {
    // max-width: 430px;
    .input-border-half {
      width: 430px;
      max-width: 430px;
    }
    .subtitle,
    .disclaimer {
      max-width: 430px;
    }

    .disclaimer {
      margin-bottom: 24px;
    }
  }

  .contacts .end-button {
    width: 100%;
  }

  .contacts .end-button .btn.btn-primary {
    margin-right: 16px;
  }
  .desktop-cta {
    background-color: white;
    padding: 24px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      0deg,
      #fff 0%,
      #fff 82.97%,
      rgba(255, 255, 255, 0) 103.45%
    );
    display: flex;
    justify-content: end;
    padding-right: 2rem;

    button {
      // margin-bottom: 8px;
      border-radius: 999px;
      background: var(--secondary-secondary-base, $accent100);
      color: white;
      padding: 16px 24px;
      border: 0;
      min-width: 334px;
    }
  }
  .final .benefici .box .box-content {
    justify-content: space-between;
  }
  .selectatview-component .choice-box {
    // max-width: 430px;
    border: 0;
    .single-box {
      display: flex;
      align-items: center;
      border: 1px solid #c2cddd;
      border-radius: 0px;

      &:last-child,
      &.last {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
      &:first-child,
      &.first {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }

      &.selected {
        border: 1px solid #272c34;
      }
    }
    &.horizontal {
      .single-box {
        &.first {
          border-top-left-radius: 1rem;
          border-top-right-radius: 0px;
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 0px;
        }

        &.last {
          border-top-left-radius: 0rem;
          border-top-right-radius: 1rem;
          border-bottom-left-radius: 0rem;
          border-bottom-right-radius: 1rem;
        }
      }
    }
  }
  #risparmio-preliminare {
    .description {
      line-height: 1rem;
      font-size: 0.75rem;
    }

    .warning {
      align-items: center;
      border: 1px solid #e67300;
      border-radius: 8px;
      padding: 16px;

      .text {
        font-size: 16px;
        line-height: 1rem;
        b {
          margin-bottom: 4px;
          display: inline-block;
        }
      }

      h2 {
        margin-bottom: 0px;
      }

      .description {
        line-height: 1rem;
        font-size: 0.75rem;
      }

      .risparmio-box-total {
        .title {
          margin-bottom: 4px;
        }
      }
      .risparmio-box {
        .img-container {
          align-self: center;
          img {
            width: 24px !important;
            height: 24px !important;
          }
        }
      }
    }
  }
  .container {
    max-width: 730px;
  }

  .thanks {
    .container {
      max-width: 80%;
    }
  }

  .stepper-component {
    // margin-top: 16px;
  }
  .select-component-input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    &::-ms-expand {
      display: none;
    }
    position: relative;
    background-image: url("/icons/icon/dropdownarrow.svg");
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 96%;
    // height: 36px;
    // color: $gray60;
  }

  .select-box-text {
    top: 15px;
    left: 10px;
    // z-index: 1;
  }
  .select-box {
    position: relative;
  }
  // .select-component-input:after{
  //             content: "▼";
  //             position: absolute;
  //             top: 4;
  //             left: calc(100% - 30px);
  //             font-size: 80%;
  //             padding: 12px 7px;
  //             background-color: DodgerBlue;
  //             color: white;
  //             pointer-events: none;
  // }

  .switch-component {
    max-width: 430px;

    .info-button {
      position: absolute;
      border-left: 1px solid #c2cddd;
      right: -3.5rem;
      padding-left: 0.5rem;
      top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .step-copy {
    line-height: 1rem;
    margin-top: 4px;
    padding-left: 12px;
    // white-space: nowrap;
    font-size: 1rem;
  }
  .next-button-box .single-next {
    // margin-right: 60px;
  }

  .next-button-box button {
    // margin-right: 60px;
  }

  .attuale {
    margin-right: 10px;
    width: 100%;

    .b {
      font-size: 14px;
      white-space: nowrap;
    }
  }

  .back {
    min-width: 113px;
  }
  select {
    padding-left: 16px;
    height: 48px;
    max-width: 100%;
    width: 430px;
  }
  .info-box {
    display: none;
  }

  .modal-fullscreen {
    width: unset;
    max-width: 600px;
    height: unset;
    margin: auto;
  }

  .select-popup {
    &.riepilogo {
      .modal-content{
        min-height: 100vh;
        display: flex;
        justify-content: space-between;
      }
      .select-component-input {
        width: 100%;
      }

      .switch-component {
        width: 100%;
        max-width: 100%;
      }

      // .end-button {
      //   padding-right: 0px;
      // }
    }

    ::placeholder {
      color: #313b49;
    }
    background-color: rgba(0, 0, 0, 0.8);
    .label-component {
      font-size: 18px;
    }
    .title {
      .field-name {
        font-size: 24px;
        line-height: 2rem;
      }
      .accent {
        // width: 48px;
        height: 48px;
        padding-left: 8px;
        padding-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-left: 0px;
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  .modal-fullscreen .modal-content {
    box-shadow: 0px 0px 16px rgba(102, 119, 144, 0.4);
    border-radius: 16px;
  }

  .riepilogo {
    .modal-dialog {
      margin-right: 0px;
      .modal-content {
        border-radius: 0px !important;
      }
    }
  }

  // .modal-pictures {
  //   max-width: 500px;
  //   max-height: 710px;
  // }

  .info-popup {
    display: block;
    position: absolute;
    padding: 30px;
    padding-top: 15px;
    top: 30px;
    right: -38px;
    min-width: 400px;
    z-index: 101;
    background: #f7f8fb;

    .title {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1rem;
      color: #000103;
      margin-bottom: 15px;
    }

    .text {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.3125rem;
      text-align: left;
    }
  }

  // Padding sinistra .landing
  // scroll finale pagina
  // taglia verso alto drop down
  // prende la virgola

  #form-header {
    //padding-top: 3rem;
    min-height: 100vh;
    max-height: 100vh;
    position: relative;
    //padding-left: 2rem;
    //padding-right: 1rem;
    // padding-top:3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // background: linear-gradient(0deg, #170a28 -1.25%, #451e7c 100%);
    // background-image: url("/icons/houses.png");
    background-color: $newPrimary;
    z-index: 1;
    // width: 800px !important;

    .title-box {
      background: unset;
      // flex: 1 1;
      // flex-basis:0;
      z-index: 4;
      .title {
        font-size: 3rem;
        line-height: 3.75rem;
      }

      .logo {
        height: 32px;
      }
    }

    .subtitle {
      margin-bottom: 40px;
    }
  }

  .landing-box {
    // padding:150px;
    min-height: 100vh;
  }

  .form-slide {
    position: relative;
    margin: 0 auto;
    padding-top: 3rem;
    background: white;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .next-button-box {
    // display:block;
    position: fixed;
    border: 0;
    // text-align: center;
    bottom: 0px;
    padding-left: 150px;
    display: flex;
    justify-items: flex-start;
    justify-content: flex-start;
    // align-items: flex-start;
    z-index: 0;
    // right: 64px;

    #next-button {
      margin-right: 2rem;
    }

    &.centered {
      padding-left: 0px;
      background-color: white;
      border: 1px solid #c2cddd !important;
      right: 0px;
      #next-button {
        margin-right: unset;
      }

      div {
        margin-right: 4rem;
        button {
          margin-bottom: 0px;
        }
      }
    }
  }

  #landing {
    padding-bottom: 0px;
    .title-box {
      flex: 0 0;
    }

    .next-button-box {
      position: unset;
    }

    .small-text {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }

    .smaller-text {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  .form-page-component {
    padding: 16px;
    padding-top: 0px;
    padding-bottom: 80px;
    max-width: 800px;
    // margin: 0 auto;
    margin-left: 3rem;
  }
  .next-button-box .single-next {
    margin-right: 64px;
    margin-bottom: 64px;
  }
  .slider-component {
    .mb-16 {
      margin-bottom: 0px !important;
      padding-top: 6px;
      padding-bottom: 6px;
    }
    input[type="number"] {
      // padding: 8px 16px 8px 8px;

      gap: 8px;

      // width: 430px;
      // height: 44px;
      text-align: left;
      background: #ffffff;

      // border: 1px solid #c2cddd;
      border-radius: 4px;
      // color: black;
    }

    .thumb {
      top: 37px;
    }

    .fill {
      top: 62px;
    }

    .small-text {
      font-size: 0.75rem;
      line-height: 0.75rem;
      text-align: right;
      max-width: 430px;
      margin-top: 4px;
      color: #667790;
    }

    .description {
      font-size: 0.875rem;
      max-width: 430px;
      color: #1c1e20;
    }
  }

  #landing {
    .landing-box {
      position: relative;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;

      .landing-text {
        border-radius: 1rem;
        padding: 2rem;
        background-color: white;
      }

      @media (max-height: 767px) {
        .landing-text {
          // background-color: red;
          padding: 1rem;

          .my-4 {
            margin-top: 0px !important;
            margin-bottom: 0px !important;
          }
          .landing-row-title {
            font-size: 1rem;
          }
          .landing-row-content {
            font-size: 0.875rem;
          }
          .landing-row-image {
            width: 5rem;
            right: 5rem;
          }
        }
      }
    }
    .next-button-box {
      // display:absolute;
      position: absolute;
      border: 0;
      text-align: left;
      bottom: 0px;
      // padding-left: 100px;
      // display: flex;
      // justify-items: flex-start;
      // justify-content: flex-start;
      // align-items: flex-start;
    }
  }

  .form-slide.thanks {
    background-color: white;
  }

  #thanks {
    min-height: 100vh;
    .logo {
      height: 32px;
    }
    .header {
      display: none;
    }
    .container {
      padding-top: 0px;
    }
  }

  .thanks {
    background-color: $newPrimary;
    color: white;
    .container {
      z-index: 2;
      h2,
      p,
      a {
        color: white;
        text-align: left;
      }
    }
    .next-button-box {
      justify-content: center;
      padding-left: 0;

      button {
        margin-left: 0;
      }
    }
  }

  .arrow-up {
    display: block;
    //   background: #F7F8FB;
    border-bottom-color: #f7f8fb;
  }

  .info-popup {
    padding: 16px;
    .content {
      margin-right: 0px !important;
      padding-right: 0px;
      a {
        color: $accent100;
      }
    }
  }
  #bolletta-carousel {
    padding-left: 96px;
    padding-right: 96px;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 48px;
    height: 48px;
    background-color: #f7f8fb;
    border-radius: 24px;
    filter: none;
    display: block;
  }

  .alberi {
    color: #667790;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .carousel-control-next-icon {
    background-image: url("/icons/icon/chevron_right.svg");
  }
  .carousel-control-prev-icon {
    background-image: url("/icons/icon/chevron_left.svg");
  }

  // .carousel-control-next-icon {
  //   position:absolute;
  //   left:128px;
  // }

  #image-wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;

    // &.casa-magica {
    //       #banner-image {
    //       max-width: 100%;
    //       margin-top: -2rem;
    //     }
    // }
  }
}

@media (min-width: 1200px) {
  //MARCO: moved to dedicated file
  // #banner-image .contatore {
  //   max-width: 80%;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
  .final #tondo {
    bottom: -20px;
  }
  // .final #right-banner .desktop-cta {
  //   width: 60%;
  // }
  #landing .title h2 {
    h2 {
      margin-bottom: 0;
      line-height: 2.5rem !important;
      font-size: 2rem !important;
    }
  }
  // #form-header {
  //   .title-box {
  //     padding-bottom:3rem;
  //   }
  // }

  .desktop-step {
    max-width: 40px;
    min-width: 40px;
  }
  .form-page-component {
    // padding: 100px !important;
    padding-top: 0px !important;
    max-width: 1000px;
    margin-left: 5rem;
  }
  .next-button-box .single-next {
    // margin-right: 100px;
  }
}

@media (min-width: 1500px) {
  //MARCO: moved to dedicated file
  // #banner-image {
  //   width: 60%;
  // }
  .desktop-step {
    max-width: 60px;
    min-width: 60px;
  }
  .form-page-component {
    // padding: 150px !important;
    padding-top: 0px !important;
    margin-left: 6rem;
  }

  .next-button-box .single-next {
    // margin-right: 150px;
  }

  .step-copy {
    font-size: 12px;
    line-height: 0.875rem;
  }
  .attuale {
    width: 100%;
    .d-flex {
      div {
        font-size: 12px;
      }
    }
  }
  .risparmio {
    .g,
    .b {
      font-size: 14px;
    }
  }
}

@media (min-width: 1500px) {
  .attuale {
    width: 100%;
    .d-flex {
      div {
        font-size: 12px;
      }
    }
  }
  .risparmio {
    .g,
    .b {
      font-size: 14px;
    }
  }
}

@media (min-width: 1300px) {
  .attuale {
    width: 100%;
    .d-flex {
      div {
        font-size: 12px;
      }
    }
  }
  .risparmio {
    .g,
    .b {
      font-size: 12px;
    }
  }
}

@media (min-width: 1281px) {
  #tondo {
    bottom: -20px;
  }
  #form-header {
    .title-box {
      flex: 1 1;
      flex-basis: 0;
      .title {
        h2 {
          font-size: 2.5rem;
          line-height: 3.25rem;
        }
        // margin-top: 80px;
        // margin-bottom: 16px;
      }
    }
  }
}
