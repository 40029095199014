
$purple100: #461e7d;
$purple80: rgba(70, 30, 125, 0.8);
$purple60: rgba(70, 30, 125, 0.6);
$purple40: rgba(70, 30, 125, 0.4);
$purple20: rgba(70, 30, 125, 0.2);

$newPrimary: #0047CC;

$gray100: #333333;
$gray80: #4f4f4f;
$gray60: #828282;
$gray40: #dddddd;
$gray20: #cecece;
$gray0: #f4f4f4;

$success100: #00855d;
$success80: rgba(0, 133, 93, 0.8);
$success60: rgba(0, 133, 93, 0.6);
$success40: rgba(0, 133, 93, 0.4);
$success20: rgba(0, 133, 93, 0.2);

$accent100: #d3135a;
$accent80: rgba(211, 19, 90, 0.8);
$accent60: rgba(211, 19, 90, 0.6);
$accent40: rgba(211, 19, 90, 0.4);
$accent20: rgba(211, 19, 90, 0.2);
// $accent100: #5738ff;
// $accent80: rgba(87, 56, 255, 0.8);
// $accent60: rgba(87, 56, 255, 0.6);
// $accent40: #5738ff66;
// $accent20: rgba(87, 56, 255, 0.2);

$warning100: rgba(230, 115, 0, 1);
$warning80: rgba(230, 115, 0, 0.8);
$warning60: rgba(230, 115, 0, 0.6);
$warning40: rgba(230, 115, 0, 0.4);
$warning20: rgba(230, 115, 0, 0.2);

$error100: #ed0529;
$error80: rgba(237, 5, 41, 0.8);
$error60: rgba(237, 5, 41, 0.6);
$error40: rgba(237, 5, 41, 0.4);
$error20: rgba(237, 5, 41, 0.2);

$cyan100: #28d7dc;
$cyan80: rgba(40, 215, 220, 0.8);
$cyan60: rgba(40, 215, 220, 0.6);
$cyan40: rgba(40, 215, 220, 0.4);
$cyan20: rgba(40, 215, 220, 0.2);



.react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
    border: 4px double transparent;
  }

  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }

  .react-toggle-track {
    width: 40px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #667790;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    // background-color: #D3135A;
    // border: 6px solid #fbbfd1;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: #D3135A;
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #D3135A;
  }

  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }

  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    // top: 1px;
    // left: 1px;
    // width: 22px;
    // height: 22px;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    // border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;

    // &:hover {
    //   -webkit-box-shadow: 0px 0px 3px 2px #1F6CF9;
    //   -moz-box-shadow: 0px 0px 3px 2px #1F6CF9;
    //   box-shadow: 0px 0px 2px 3px #1F6CF9;
    // }
  }

  .react-toggle--checked .react-toggle-thumb {
    right: 4px;
    left: unset;
    // left: 27px;
    border-color: white;
  }

  .react-toggle--focus {
    border-radius:1rem;
    border: 4px double #1F6CF9;
    box-sizing:content-box;
    // -webkit-box-shadow: 0px 0px 3px 2px #1F6CF9;
    // -moz-box-shadow: 0px 0px 3px 2px #1F6CF9;
    // box-shadow: 0px 0px 2px 3px #1F6CF9;
  }
  // .react-toggle--focus .react-toggle-thumb {
  //   -webkit-box-shadow: 0px 0px 3px 2px #1F6CF9;
  //   -moz-box-shadow: 0px 0px 3px 2px #1F6CF9;
  //   box-shadow: 0px 0px 2px 3px #1F6CF9;
  // }

  // .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  //   -webkit-box-shadow: 0px 0px 5px 5px #1F6CF9;
  //   -moz-box-shadow: 0px 0px 5px 5px #1F6CF9;
  //   box-shadow: 0px 0px 5px 5px #1F6CF9;
  // }



.gri-select {
    border: 6px solid transparent;
    box-sizing: border-box;
    &:hover {
      border: 6px solid #fbbfd1;
      border-radius: 8px;
    }
  }
  .dsy-button {
    outline: 0 !important;
    padding: 12px 16px !important;
    border-image-outset: 4px !important;
    line-height: 1rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    min-height: 48px !important;
    // padding: 0.25rem !important;

    &:disabled{
      cursor: not-allowed !important;
    }
    &.outline {
      border-radius: 4px !important;
      border: 2px solid white !important;
      color: white !important;
      background-color: unset !important;

      &:focus {
        border: 4px solid #d3135a !important;
      }

      &:disabled {
        opacity: 0.6 !important;
      }
    }
    &.secondary {
      border-radius: 4px !important;
      border: 2px solid #d3135a !important;
      color: #d3135a !important;
      background-color: white !important;

      &:focus {
        border: 4px solid #d3135a !important;
      }

      &:disabled {
        opacity: 0.6 !important;
      }
    }
    &.link {
      border: none !important;
      color: $accent100 !important;
      background-color: transparent !important;

      &:hover {
        text-decoration: underline !important;
      }
      &:focus {
        text-decoration: underline !important;
      }
      &:disabled {
        color: #c2cddd !important;
      }
    }
    &.primary {
      background-color: $accent100 !important;
      color: white !important;
      border-radius: 0.5rem !important;
      border:0 !important;
      border: 6px solid transparent !important;

      &:focus {
        border: 6px double white !important;
      }

      &:hover {
        background-color: #c2185b !important;
      }

      &:disabled {
        background-color: #c2cddd !important;
        color: black !important;

      }
    }
  }

  .gri-switch-component {
    display: flex;
    justify-content: space-between;
    label {
      font-size: 1rem;
    }
  }

  .gri-checkbox-component {
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    display: flex;
    align-content: center;
    justify-content: center;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    border: 2px solid #667790;
    border-radius: 4px;
    position:relative;
    cursor: pointer;
    &:checked {
        background-color: $accent100;
        border: 2px solid $accent100;
        &::before {
            content: "";
            background-image: url("../components/dsy-inhouse/check.svg");
            position:absolute;
            top: 2px;
            left: 2px;
            right: 2px;
            bottom: 2px;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &:focus-visible {
        // box-shadow: 0px 0px 2px 2px  $accent60;
        // border-radius: 0.5rem;
        &::after {
          content:"";
          display: block;
          position: absolute;
          // opacity: 0.6;
          width: 26px;
          height: 26px;
          border: 2px double #1F6CF9;
          top:-5px;
          left: -5px;
          border-radius: 0.5rem;
          // box-shadow: 0,0,0,4px #c2cdd;
      }
    }
    &:active {
        &::after {
                content:"";
                display: block;
                position: absolute;
                // opacity: 0.6;
                width: 26px;
                height: 26px;
                border: 2px double #1F6CF9;
                top:-5px;
                left: -5px;
                border-radius: 0.5rem;
                // box-shadow: 0,0,0,4px #c2cdd;
            }
    }
    &:hover {
        &::after {
                content:"";
                display: block;
                position: absolute;
                // opacity: 0.6;
                width: 26px;
                height: 26px;
                border: 2px double #1F6CF9;
                top:-5px;
                left: -5px;
                border-radius: 0.5rem;
                // box-shadow: 0,0,0,4px #c2cdd;
            }
        }
  }


  .gri-buttons {
    box-shadow: 0px 2px 8px 0px  rgba(102, 119, 144, 0.20);
    padding: 0.25rem;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100%;

    button {
      white-space: nowrap;
    }
  }

  #gri-lottie {
    #lottie-play-stop-button {
      display:none;
    }

    &:hover {
      #lottie-play-stop-button {
        display:block;
      }
    }
  }


.redesign-product-box {
  border-radius: 0.75rem;
  padding: 1rem 0.75rem;
  margin-bottom: 1rem;
/* elevation-shadow */
  box-shadow: 0px var(--shadow-size, 0px) var(--shadow-blur, 16px) 0px var(--Effects-shadow-elevation, rgba(102, 119, 144, 0.40));

  &.selected {
    box-shadow: 0px var(--shadow-size, 0px) var(--shadow-blur, 16px) 0px var(--primary---primary-60, #0047CC);
  }

  .extra {
    border-radius: var(--Border-radius-border_radius-0_25, 4px) var(--Border-radius-border_radius-0_25, 4px) var(--Border-radius-border_radius-0, 0px) var(--Border-radius-border_radius-0, 0px);
    background: linear-gradient(90deg, #F5C84A 0%, #F5B500 100%);
    box-shadow: 0px 2px 1px 0px rgba(77, 71, 0, 0.60);
    padding: 4px 8px;
    margin-top: 1rem;
    position:absolute;
    left: 0;
    right: 0;
    top: -50px;
  }
  .product-image {
    height: 100%;
    padding: 1rem;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--Border-radius-border_radius-0_5, 8px);
  background: #f7f8fb;
    img { 
      max-width: 100%;
      max-height: 300px;
    }
  }

  .serie {
    color: #667790;
    font-size: 0.875rem;
    margin-bottom: 0;
  }

  .title {
    font-size: 1.875rem;
    color: #131416;
  }

  .price-box {
    padding: 0.75rem;
    border-radius: 4px;
    background-color: #f7f8fb; //linear-gradient(0deg, #EFF2F7 0%, #EFF2F7 100%), var(--neutrals---neutral-60, #667790);
  }

  .mx-12 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .scopri {
    color: #D3135A;
    cursor: pointer;
  }

  .price-text {
    background: linear-gradient(90deg, #0047CC 0%, #3B80FF 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    p {
      margin-bottom: 0;
    }
      .big {
        font-size: 3.5rem;
        line-height: 3.5rem;
      }

      .small {
        text-align: left;
        
      }

      .f22 {
        font-size: 1.35rem;
      }
  }
}

