.ocr-page {
  margin-top: 3rem;
  @media (max-width: 768px) {
    margin-top: 1rem;
  }
  .title h2 {
    font-size: 40px;
    line-height: 3.25rem;
    font-family: "Roobert";
    font-style: normal;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  .ocr-section {
    display: flex;
    width: 732px;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    margin-left: 3rem;

    @media (max-width: 992px) {
      margin-left: 1rem;
      margin-right: 1rem;
      width: unset;
    }

    @media (min-width: 1200px) {
      margin-left: 6rem;
    }
    
  }

  .ocr-info-section {
    border: 1px solid #C2CDDD;
    border-radius: 16px;
    padding: 1rem;
    
    .ocr-info-section-title {
      font-family: "Roobert";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.4px;
    }
    .ocr-info-section-icon {}

  }

  dsy-divider {
    height: 1px;
    width: 100%;
  }
}

.ocr-header {
  max-height: unset !important;
  @media (max-width: 576px) {
    .title {
      h2 {
        font-size: 18px !important;
        line-height: 27px !important;
      }
    }
  }
  .ocr-header-list-container {
    margin-top: 1rem;
    .ocr-header-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      li {
        font-family: "Roobert";
        font-weight: 400;
        font-style: normal;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0px;
        strong {
          font-weight: 800;
        }
      }
    }
  }
  .ocr-header-image {
    width: auto;
    height: 285.421px;
    flex-shrink: 0;
    
    @media (max-width: 768px) {
      width: 50%;
      height: unset;
    }

    @media (max-width: 992px) {
      width: 45%;
      height: unset;
    }
  }
}
.ocr-dropzone-container {
  .ocr-dropzone {
    display: flex;
    width: 100%;
    height: 176px;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    border-radius: 8px 8px 8px 8px;
    border: 1px dashed #c2cddd;
    background: #f7f8fb;

    /* elevation-shadow */
    box-shadow: 0px var(--shadow-size, 2px) var(--shadow-blur, 8px) 0px
      var(--shadow-color, rgba(102, 119, 144, 0.2));

    .ocr-dropzone-area {
      cursor: pointer;
    }

    .ocr-dropzone-area-text {
      color: var(--text-secondary, #272c34);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      .click-text {
        color: #D3135A;
      }
    }
    .ocr-dropzone-area-file-info {
      color: #667790;
      /* components/comp_xs */
      font-family: "Roobert";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .ocr-filebox {
    border-radius: 8px;
    border: 1px solid #c2cddd;
    background: #f7f8fb;

    /* elevation-shadow */
    box-shadow: 0px 2px 8px 0px rgba(102, 119, 144, 0.2);

    .ocr-filebox-filesize {
      color: #272c34;
      font-family: "Roobert";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; /* 100% */
      letter-spacing: 0px;
    }
    .ocr-filebox-progress-bar {
      height: 8px;
      align-self: stretch;
      border-radius: 320px;
      background: #cbdaf6;
    }
  }
  #trashbin-icon {
    cursor: pointer;
    height: 25px;
  }
  
}
.ocr-strong {
  font-weight: 300 !important;
  font-family: "Roobert Bold" !important;
}

/* CircularSpinner.css */
.ocr-circular-spinner {
  position: relative;
  display: inline-block;
}

.ocr-circular-spinner-track {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px solid #e9ecef;
  border-radius: 50%;
}

.ocr-circular-spinner-arc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ocr-circular-spinner-arc svg {
  width: 100%;
  height: 100%;
  animation: spinnerRotation 1.5s linear infinite;
  transform-origin: center;
}

@keyframes spinnerRotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ocr-result-chips {
    max-width: 100%;
    font-size: 16px;
    margin-right: 0.25rem;
}

.ocr-result-chips-positive {
    background-color: #F4F8FF !important;
    border: 2px solid #CBDAF6 !important;
}

.ocr-result-chips-estimated {
  background-color: #FCF1E6 !important;
  border: 2px solid #E67300 !important;
  img {
    fill: #E67300
  }
}

.ocr-result-chips-negative {
    background-color: #FFE9E8 !important;
    border: 2px solid #ED0529 !important;
    svg {
        color: #ED0529 !important
    }
}

.ocr-field-note {
  .ocr-field-note-text {
    font-size: 14px;
  }
}