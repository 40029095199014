@font-face {
    font-family: "Roobert";
    src: url("./fonts/WEB/RoobertENEL-Regular.woff") format("woff"),
      url("./fonts/WEB/RoobertENEL-Regular.woff2") format("woff2");
  }
  
  @font-face {
    font-family: "Roobert Light";
    src: url("./fonts/WEB/RoobertENEL-Light.woff") format("woff"),
      url("./fonts/WEB/RoobertENEL-Light.woff2") format("woff2");
  }
  
  @font-face {
    font-family: "Roobert Bold";
    src: url("./fonts/WEB/RoobertENEL-Bold.woff") format("woff"),
      url("./fonts/WEB/RoobertENEL-Bold.woff2") format("woff2");
  }
  
  // @font-face {
  //   font-family: "Roobert";
  //   src: url("./fonts/WEB/RoobertENEL-Black.woff") format("woff"),
  //     url("./fonts/WEB/RoobertENEL-Black.woff2") format("woff2");
  // }
  
  body {
    font-family: "Roobert";
  }
  
  .small-text {
    font-size: 0.875rem;
    color: #667790;
    line-height: 1rem;
  }
  
  $purple100: #461e7d;
  $purple80: rgba(70, 30, 125, 0.8);
  $purple60: rgba(70, 30, 125, 0.6);
  $purple40: rgba(70, 30, 125, 0.4);
  $purple20: rgba(70, 30, 125, 0.2);

  $newPrimary: #0047CC;
  
  $gray100: #333333;
  $gray80: #4f4f4f;
  $gray60: #828282;
  $gray40: #dddddd;
  $gray20: #cecece;
  $gray0: #f4f4f4;
  
  $success100: #00855d;
  $success80: rgba(0, 133, 93, 0.8);
  $success60: rgba(0, 133, 93, 0.6);
  $success40: rgba(0, 133, 93, 0.4);
  $success20: rgba(0, 133, 93, 0.2);
  
  $accent100: #D3135A;
  $accent80: rgba(211, 19, 90, 0.8);
  $accent60: rgba(211, 19, 90, 0.6);
  $accent40: rgba(211, 19, 90, 0.4);
  $accent20: rgba(211, 19, 90, 0.2);
  
  $warning100: rgba(230, 115, 0, 1);
  $warning80: rgba(230, 115, 0, 0.8);
  $warning60: rgba(230, 115, 0, 0.6);
  $warning40: rgba(230, 115, 0, 0.4);
  $warning20: rgba(230, 115, 0, 0.2);
  
  $error100: #ed0529;
  $error80: rgba(237, 5, 41, 0.8);
  $error60: rgba(237, 5, 41, 0.6);
  $error40: rgba(237, 5, 41, 0.4);
  $error20: rgba(237, 5, 41, 0.2);
  
  $cyan100: #28d7dc;
  $cyan80: rgba(40, 215, 220, 0.8);
  $cyan60: rgba(40, 215, 220, 0.6);
  $cyan40: rgba(40, 215, 220, 0.4);
  $cyan20: rgba(40, 215, 220, 0.2);

  .close{
    text-align: right;
    justify-self:center;
    // align-self:center;
    flex-grow: 1;
  }

//MARCO: moved to dedicated file
// @media(min-width: 500px) and (max-width: 766px ) {
//   #banner-image {
//     width: 50%;
//     // margin-top: -150px;
//   }

//   #landing #banner-image {
//     width: 50%;
    
//   }
// }


@media (min-width: 766px) and (max-width: 992px) {
  .thanks  {
    .container {
    h2, p {
      color: white;
    }
  }
  }
  .product {
    flex-wrap: wrap;
  }
  .final .header .right-side .prodotti .product .bottom  {
    margin-left: 0;
  }
  .desktop-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    border-top: 1px solid #c2cddd;
  }
    // .modal-dialog {
    // --bs-modal-width: 500px;
    // }
    #image-wrapper {
      // margin-top: -50px;
    }
    //MARCO: moved to dedicated file
    // #banner-image {
    //   width: 30%;
    //   max-width: 150px;
    //   // margin-top: -150px;
    // }

    #landing {
      padding-bottom: 0;
    }
    .landing-box {
      //margin-top: 2rem;

      .landing-text {
        background-color: white;
      }
    }

    .title-box {
      background-image: url('../icons/banner/bkg-90.svg');
      background-position-x: center;
      background-position-y: bottom;
    
      background-color: #0047CC;
      background-repeat: no-repeat;
      //margin-top: -10px;
      //padding: 1.5rem;
      
      //width: 100%;
      //max-height: 280px;
      height: 300px;
      width: 100%;
    }

    #landing {
      //background: white;
      //background-image: unset !important;

      main  {
        min-height: unset;
      }
    }
    

    #next-button {
      width: 244px;
    }

    #landing .subtitle  {
      display: none;
    }

    .select-popup .classe-energetica-box {
      // .opt-button {
      //   position:absolute;
      //   // width: 24px;
      //   // height: 24px;
      //   // left: 27px;
      //   // top: 15px;
  
      //   width: 35px;
      //   height: 35px;
      //   left: 34px;
      //   top: 22px;
      // }

      .blue-box-ce  {
        // display: none;
      }
      .active-text {
        // top:19px;
        // font-size:24px;
        // left:70px;
        
      }
    }
    .risparmio-box {
      .description{
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
    }
    .close {
      img {
        width: 16px;
        height: 16px;
      }
    }
    .so-non-so {
      margin-top:32px;
      margin-bottom: 32px;
    }
    .select-popup .confirm button {
      &:disabled {
        color: #667790;
        opacity: 0.699999988079071;
      }
    }

    .back {
      width: 104px;
      height: 48px;
    }
    .final {
      
      .btn-group {
        padding-right: 32px;
        padding-left: 32px;
      }
      .review-or-download {
        margin-bottom:32px;
        .mt-8 {
          margin-top:24px;
        }
      }
      .book-appointment {
        margin-top: 32px;
        background: white;
        color: #000103;
        padding-top: 0px;
        // padding-bottom: 32px;

        .container {
          .row {
            flex-direction: column;

            button{
              justify-self:center;
              align-self: center;
              width: 344px;
            }
          }
        }
  
        h2 {
          font-weight: 700;
          font-size: 1.5rem;
          line-height: 2rem;
          text-align: center;
          color: #461e7d;
          margin-bottom:32px;
          margin-bottom:16px;
        }
  
        p {
          font-weight: 400;
          font-size: 1.125rem;
          line-height: 1.75rem;
          text-align: center;
          color: #000103;
          margin-bottom: 16px;
        }
  
        button {
          background-color: $accent100;
          color: white;
          border: 0;
          font-weight: 700;
          font-size: 1rem;
          line-height: 1rem;
          // text-transform: uppercase;
          color: #ffffff;
          width: 100%;
          border: 0;
        }
      }
    }

    .final .review-or-download .btn.outline.btn-primary.mr-16 {
      margin-right: 0px;
      margin-bottom: 24px;
    }
    

    .alberi {
      font-size: 0.75rem;
      line-height: 1.125rem;
      text-align: right;
      color: #667790;
    }

    .final .benefici {
      padding-top: 32px;
      // padding-bottom: 24px;
      padding-bottom: 0px;

      &.container-fluid .row {
        padding-left: 32px;
        padding-right: 32px;
      }
      .title {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2rem;
        text-align: left;
      }
      
      .subtitle {
        text-align: left;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin-bottom: 24px;
      }

      .same-height .box-info {
        font-size: 1.125rem;
        line-height: 1.5rem;
        text-align: left;
        margin-bottom: 16px;
        
      }

      .box .box-content {
        flex-direction: row;
        align-items: center;
        // background-color: red;
        .box-top {
          // flex-shrink: 0;
          flex-grow:1;
          flex:1;
          margin-right:16px;
          margin-bottom: 0px;

          .sub {
            font-weight: 700;
            font-size: 1rem;
            line-height: 1rem;
            justify-self: center;
            align-self: center;
          }
        }

        .left, .right {
          font-size:12px;
          .mb-8 {
            font-size:14px;
          }
        }

        .box-middle {
          // flex-shrink: 0;
          flex-grow:1;
          flex:1;
        }

       
      }

      .disclaimer {
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin-top: 0px;
      }
    }

   .header-mobile {
    .container-fluid .row {
      padding-left:32px;
      padding-right:32px;

      &.title{
        padding-left:32px;
        padding-right:32px;
      }
    }

    .sub-header .prodotti  {
      background: #F7F8FB;
      padding-bottom: 32px;

      .product {
        background-color: white;
        display:flex;
   

        .top {
          border-bottom: 0;
          border-right: 1px solid  #C2CDDD;
          padding-right:16px;
        }

        .bottom-row {
          display: flex;
          // justify-content: space-between;
          // justify-content: center;
          align-items: center;
          .bottom {
            // display: flex;
            // justify-content: space-between;
            width: 100%;
            padding-left:16px;
            &.mt-16{
              margin-top:0px;
            }
          }
        }
        
      }
    }
   }

    // .row {
    //   padding-left: 32px;
    //   padding-right:32px;
    // }
    #final-message{
      margin-top:32px;
      margin-bottom:16px;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding-left:32px;
      padding-right:32px;
      color: #313B49;
    }
    .header-mobile .sub-header {
      .buttons {
        // padding-left:32px;
        // padding-right:32px;
        .btn-outline-primary {
          border-right: 1px solid gray;
          border-radius: 0px !important;
          &.active {
            color: $accent100 !important;
          }
        }
      }
      
    }
    .slider-component-switch {
      width: 343px;
    }
    .mb-32 { 
      margin-bottom:32px;
    }
    .my-32 { 
      margin-bottom: 32px; 
      margin-top:32px;
    }
 

    #risparmio-preliminare {
      padding-top: 3rem;
      .warning {
        padding: 16px 20px ;
        align-items: center;
        border: 2px solid #E67300;
        border-radius: 8px;
  
        .text{
          b {
            font-size:14px;
            line-height: 0.875rem;
          }
          font-size: 0.75rem;
          line-height: 1.125rem;
        }
      }
    }
    #thanks {
      .container {
        max-width: 464px;
      }
    }
    
    .sliderwiththumb  {
      
      .fill {
        top:76px;
      }
      .thumb {
        background-color: #EFF2F7;
        top:37px;
        &:after {
            background-color: #EFF2F7;
        }
    }
      .shad1 {
        height: 48px;
        background: #EFF2F7;
        input {
          background: #EFF2F7;
        }

       
      }

      
    }
    .selectatview-component {
  
      .title {
        font-size:20px;
        margin-bottom:32px;
      }
    .choice-box{
      // max-width: 328px;
      border:0;
      .single-box {
        margin-bottom: 16px;
        border: 1px solid #C2CDDD;
        border-radius: 16px;
        &:last-child{
           border-bottom: 1px solid #C2CDDD;
        }

        &.selected  {
          border: 1px solid var(--secondary-secondary-base, $accent100);
        }
      } 
    }
  }
  
    .riepilogo-label {
      margin-top:24px;
    }
  
    .switch-component {
      max-width: 350px;
      // padding-right: 0;
    }
    .switch-description {
      max-width: 350px;
    }
    .big-accent{
      max-width: 344px;
      margin:0 auto;
      display: inherit;
    }
  
    #landing {
      .landing-text {
        font-size:20px;
        padding-top:40px;
        padding-left: 32px;
        padding-right: 32px;
        line-height: 1.875rem;
        border-radius: 1rem;
        padding: 2rem;
        background-color: white;
      }
  
      .list-item-title{
        line-height: 2rem;
        position: relative;
      }
  
      .simulazione3{
        display: flex; 
        flex-direction: row;
        flex-grow: 1;
        justify-content: space-between;
      }
  
      // #info {
      //   position:absolute;
      //   right:0px;
      // }
  
      .small-text{
        margin-bottom:16px;
        line-height: 1.5rem;

        &.d {
          font-size: 1.125rem;
          line-height: 1.5rem;
        }
      }
      .smaller-text {
        font-size: 0.875rem;
        line-height: 145%;
        letter-spacing: 0.014px;
      }
      .next-button-box {
        position: fixed;
        bottom: 0;

      
      }
    }
    .risparmio-buttons{
      display: flex;
      flex-direction: column;
      justify-content: center !important;
      align-items:center !important;
      button {
        max-width: 344px;
        width: 344px !important;
        text-align: center;
        margin:0 auto;
      }
    }
  .form-page-component{
    padding:32px;
    padding-top:3rem;
    padding-bottom:80px;
  }
    .address-picker, input, select, .sliderwiththumb, .select-component, .slider-container  {
      max-width: 340px !important;
    }
  
    .modal-content {
      padding: 32px;
    }
  
    .modal-fullscreen {
      width: unset;
      max-width: 600px;
      height: unset;
      margin: auto;
    }
  
    .select-popup {
      background-color: rgba(0, 0, 0, 0.5);
      &.riepilogo{
        padding-top:112px;
        padding-bottom:112px;
        background: rgba(14, 20, 26, 0.85);
        z-index: 10004;
        // opacity: 0.85;

        .accordion-button {
          &:after {
            background-color: #EFF2F7;
            padding:8px;
            border-radius: 8px;
            width: 32px;
            height: 32px;
            background-position: center;
            background-image: url("/icons/icon/dropdownarrow.svg");
            background-size: 8px 4px;
          }
        }
      }
    }
  
    .select-popup .title {
      border-bottom: 0;
    }
    .select-popup .options {
      padding-bottom: 0px;
    }
  
    .modal-fullscreen .modal-content {
      box-shadow: 0px 0px 16px rgba(102, 119, 144, 0.4);
      border-radius: 16px;
  
      .title {
        box-shadow: none;
      }
    }
  
    .info-popup {
      .big-accent {
        display: block;
      }
    }
  
    .select-popup .confirm {
      position: unset;
      border: 0;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  
    select {
      padding-left: 16px;
      height: 48px;
      max-width: 100%;
      width: 430px;
    }

    .next-button-box button {
        width: 344px;
        max-width: 344px;

        &:disabled {
          opacity: 0.699999988079071;
          color: #667790
        }
    }

   
  }