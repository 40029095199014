#banner-image {
  z-index: 10;
  // caso in cui sono di lato in versioni desktop
  &.side-image {
      position: absolute;
      left: 50%;
      top: 60%;
      transform: translate(-50%, -50%);
      width: 415px;
      height: 436px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      @media screen and (max-height: 500px) {
        width: 209px !important;
        height: 220px !important;
        top: 70%;
      }

      @media screen and (min-height: 501px) and (max-height: 600px) {
        width: 307px !important;
        height: 323px !important;
        top: 65%;
      }
    
    img {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    .lottie {
      position: absolute !important;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
  // caso in cui sono mobile con l'immagine sopra
  &.header-image {
    position: absolute;
      
      //transform: translate(50%, -50%);
      right: 5%;
      bottom: -5%;
      width: 168.47px;
      height: 177px;
      display: flex;
      align-items: center;
      justify-content: center;
    
    img {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    .lottie {
      position: absolute !important;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  

  // Child element styles
  .contatore {
    top: 30%;
    @media (max-width: 992px) {
      top: 0%
    }
    @media (min-width: 1200px) {
      max-width: 80%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.maialino-risparmio {
  position: relative;
  width: 100%;
  height: 100%;
  .lottie {
    bottom: 25%;
  }
  @media screen and (max-height: 500px) {
    width: 50%;
    .lottie {
      bottom: 65%;
    }
  }

  @media screen and (min-height: 501px) and (max-height: 600px) {
    width: 65%;
    .lottie {
      bottom: 45%;
    }
  }
}

.cashpile-group-component:first-child:nth-last-child(4),
.cashpile-group-component:first-child:nth-last-child(4) ~ #banner-image {
 
  bottom: -10% !important;
  
  
  /* styles */
}

.risparmio-finale-header {
  .title-text {
    text-align: left;
    width: 60%;
  }
  .header-image {
    top: 18%;
  }
}

@media (max-width: 992px) {
  #banner-image {
    &.header-image {
      bottom: -3%;
    }
    &.header-image:has(.contatore) {
      bottom: -10% !important;
    }
  }
}

// HO PROVATO AD USARE DISPLAY GRID MA SENZA SUCCESSO DA MOBILE DIVENTA UN CASINO
// .solution-boxes-container {
//   position: relative;
//   display: grid;
//   width: 100%;
//   height: 168.47px;
//   grid-template-columns: repeat(6, minmax(100px, 1fr));
//   justify-content: center;

//   .solution-box {
//     justify-self: center;
//     align-self: center;
//     position: relative;
//     width: 100%;
//     height: 150px;
//     grid-column: auto / span 2;
//     img {
//       position: relative;
//       max-width: 100%;
//       margin: auto;
//     }
//   }
//   .solution-box:nth-last-child(2),
//   .solution-box:last-child {
//     grid-column: auto / span 3;
//   }
//   .solution-box:nth-last-child(2) {
//     text-align: right;
//   }
//   .solution-box:last-child {
//     text-align: left;
//   }
// }